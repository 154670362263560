<template>
    <div class="main-container">
       <Header />
        <main>
            <SliderDestaque :sliderData="slider_noticias" />
            <ProdutosHome />
            <InfoHome />
            <InfoHomeInferior />    
            <!-- <ArtigosHome />     -->
            <GaleriaHome :GaleriaData="slider_galerias" />
            <BannerHome :BannerData="slider_banners" />
        </main>
        <Footer />

        <!-- <OffCanvasMobileMenu /> -->

        <!-- back to top start -->
        <back-to-top class="scroll-top" id="scrollUp" bottom="40px">
             <i class="ti-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import SliderDestaque from '../components/sections/SliderDestaque';
    import ProdutosHome from '../components/sections/ProdutosHome';
    //import ArtigosHome from '../components/sections/ArtigosHome';
    import InfoHome from '../components/InfoHome';
    import InfoHomeInferior from '../components/sections/InfoHomeInferior';
    import GaleriaHome from '../components/sections/GaleriaHome';
    import BannerHome from  '../components/sections/BannerHome';
    import Footer from '../components/Footer';
    // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
    import axios from 'axios';
    export default {
        data () {
            return {
               slider_noticias: [],
               slider_galerias: [],
               slider_banners: []
            }
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        components: {
            Header,
            SliderDestaque,
            ProdutosHome,
            InfoHome,
            InfoHomeInferior,
            GaleriaHome,
            BannerHome,
            // ArtigosHome,
            Footer,
            //OffCanvasMobileMenu
        },
        metaInfo: {
            title: 'Vix Inspeções',
            titleTemplate: `%s - home`,
            meta: [{
            name: 'description',
            content: 'Vix Inspeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
        axios.get(`https://sistema.gouarts.com/api/noticias?status=3&cliente=vitoriainspecoes`).then(res => {
            this.slider_noticias = res.data.noticias;  
        }).catch(err => {
            console.log(err);
        })
        axios.get(`https://sistema.gouarts.com/api/galerias?cliente=vitoriainspecoes&status=3`).then(res => {
            this.slider_galerias = res.data.galerias;  
        }).catch(err => {
            console.log(err);
        })
        axios.get(`https://sistema.gouarts.com/api/banners?cliente=vitoriainspecoes&status=3`).then(res => {
            this.slider_banners = res.data.banners;  
        }).catch(err => {
            console.log(err);
        })
}
</script>
