<template>
  <div class="sliderhome pt-5 pb-5">
    <div class="container">
        <swiper class="swiper" :options="swiperOption">    
        <swiper-slide v-for="item in sliderData" :key="item.id">
          <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 text-right">
              <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + item.foto" class="img-fluid" :alt="item.titulo">
            </div>
             <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
               <h1 data-animation="fadeInUp" data-delay=".5s">{{ item.titulo }}</h1>
               <h2>{{ item.resumo }}</h2>
               <router-link class="btn btn-success" :to="`/noticia/${item.id}/${item.slug}`">SAIBA MAIS <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></router-link>
             </div>
          </div>
        </swiper-slide>
        
        <div class="swiper-button-prev rounded-circle" slot="button-prev"></div>
        <div class="swiper-button-next rounded-circle" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-navigation',
    title: 'Navigation',
    components: {
      Swiper,
      SwiperSlide
    },
    props: ['sliderData'],
    data() {
      return {
        swiperOption: {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
 
</style>