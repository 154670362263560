
<template>
  <div class="services-area1 section-padding10">
  <div class="container">
      <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div class="section-tittle mb-4">
                            <div class="front-text">
                                <h2 class="">NOSSAS INSTALAÇÕES E OUTRAS GALERIAS</h2>
                            </div>                           
                        </div>
                    </div>
                </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="item in GaleriaData" :key="item.id">
            <div class="card">
                <router-link :to="`/galeria/${item.id}/${item.slug}`"><img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + item.capa" class="img-fluid" :alt="item.titulo"></router-link>
            
            <div class="card-body">
                <h3>{{ item.titulo }}</h3>
                <p class="card-text">
                    {{ item.resumo }}
                </p>
            </div>
            </div>
          
        </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper> 
    </div>
  </div>
  <div class="row">
      <router-link  class="btn" :to="{name:'Galerias', params:{pag:'galerias'}}">CONFIRA MAIS GALERIA DE FOTOS [+]</router-link>
  </div>
  </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-loop-group',
    title: 'Loop mode with multiple slides per group',
    components: {
      Swiper,
      SwiperSlide
    },
    props: ['GaleriaData'],
    data() {
      return {
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          slidesPerGroup: 3,
          loop: true,
          loopFillGroupWithBlank: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
      margin-bottom: 20px;
  }
  .card img {
      width: 100%;
      height: 250px;
  }

  @media (max-width: 576px)
{
  .card img {
      width: 100%;
      height: 100px;
  }

  .card-body h3 {
    font-size: 20px;
  }
  .card-body p {
    font-size: 14px;
  }
}
</style>