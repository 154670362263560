<template>
    <section class="support-company-area fix pt-10">
            <div class="support-wrapper align-items-end">
                <div class="left-content">
                    <!-- section tittle -->
                    <div class="section-tittle section-tittle2 mb-55">
                        <div class="front-text">
                            <h2 class="">Nossa Empresa</h2>
                        </div>
                    </div>
                    <div class="support-caption">
                        <p class="pera-top">{{info_home.home_titulo_principal}}</p>
                        <p>{{info_home.home_resumo_principal}}  </p>
                        <router-link class="btn red-btn2" :to="{name:'Pagina', params:{pag:'sobre-a-empresa'}}">saiba mais sobre nós</router-link>
                    </div>
                </div>
                <div class="right-content">
                    <!-- img -->
                    <div class="right-img">
                        <img src="../assets/img/safe_in.png" alt="">
                    </div>
                    <div class="support-img-cap text-center">
                        <span>Vix</span>
                        <p>Inspeções</p>
                    </div>
                </div>
            </div>
        </section>   
    
</template>

<script>
import axios from 'axios';
export default {
    name: "Footer",
    created(){
        axios.get("https://sistema.gouarts.com/api/home?cliente=vitoriainspecoes").then(res => {
             this.info_home = res.data.configuracoes;
             
        }).catch(err => {
           console.log(err);
           
        })
    },
    data()
    {
        return {
            info_home: [],
        }
    }   
}
</script>