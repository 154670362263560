<template>
  <div class="services-area1 section-padding10">
  <div class="container">
      <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle mb-4">
                            <div class="front-text">
                                <h2 class="">PORTAIS E SERVIÇOS</h2>
                            </div>                           
                        </div>
                    </div>
                </div>
        <div class="row">
      <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="item in BannerData" :key="item.id">
        <div class="card">
            <a :href="`${item.url}`" :target="`${item.destino}`"><img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + item.capa" class="img-fluid" :alt="item.titulo"></a>
        </div>
       
    </swiper-slide>
    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper> 
  </div>
  </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-loop-group',
    title: 'Loop mode with multiple slides per group',
    components: {
      Swiper,
      SwiperSlide
    },
    props: ['BannerData'],
    data() {
      return {
        swiperOption: {
          slidesPerView: 6,
          spaceBetween: 30,
          slidesPerGroup: 6,
          loop: true,
          loopFillGroupWithBlank: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  
  .card img {
      width: 100%;
  }
</style>