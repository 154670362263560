<template>
<div class="services-area1 section-padding10">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle mb-4">
                            <div class="front-text">
                                <h2 class="">NOSSOS SERVIÇOS</h2>
                            </div>                           
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6" v-for="produto in lista_produtos" :key="produto.id">
                    <Produtos :produto="produto" />
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import Produtos from '@/components/Produtos.vue';
    import axios from 'axios';
    export default {
        components: {
            Produtos
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                lista_produtos: [],
            }
        }
    };
     function doSomething() {
        axios.get(`https://sistema.gouarts.com/api/produtos?status=3&cliente=vitoriainspecoes`).then(res => {
            this.lista_produtos = res.data.produtos;  
        }).catch(err => {
            console.log(err);
        })
}
</script>
