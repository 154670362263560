<template>
<section class="contact-with-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-9 offset-xl-1 offset-lg-1">
                        <div class="contact-us-caption">
                            <div class="team-info mb-30 pt-45">
                                <div class="section-tittle section-tittle4">
                                    <div class="front-text">
                                        <h2 class="">AGENDE SUA VISTORIA </h2>
                                    </div>
                                </div>
                                <p>Agende uma vistoria conosco, por e-mail, telefone e WhatsApp</p>
                                <router-link class="white-btn" :to="{name:'Pagina', params:{pag:'vistorias'}}">Faça uma vistoria agora</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</section>
</template>

<script>
    export default {
        
    };
</script>

<style lang="scss">
.contact-with-area {
background: url('../../assets/img/section-bg2.jpg');
}
</style>